@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";

.cmsBreadcrumbsComponent {
  a {
    max-width: calc((100vw - 300px) / 5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
