/* Using ADI Palette from Analog Brand guidelines

https://analog-mm.digizuite.com/asset/28466/ADI-Corporate-Brand-Guidelines

ADI Colors:

Primary:
  Blue: #0067B9
  Black: #101820

Secondary:
  Purple: #8637BA
  Green: #00AE65
  ADI Light Blue: #41B6E6
  Yellow: #D6E342
  Light Gray: #B1B3B3
  Dark Gray: #53565A
  Orange: #FF7500
  Red: #A50034
*/
$pt-app-background-color: #f5f8fa;
$dark-navbar-background-color: #101820;
$pt-intent-primary: #0067B9;
$pt-intent-success: #00AE65;
$pt-intent-warning: #FF7500;
$pt-intent-danger: #A50034;

$pt-link-color: #0067B9;
$pt-outline-color: rgba($pt-link-color, 0.2);

$pt-font-family: -apple-system,
-apple-system-body,
"BlinkMacSystemFont",
"Segoe UI",
"Roboto",
"Oxygen",
"Ubuntu",
"Cantarell",
"Open Sans",
"Helvetica Neue",
"Icons16", arial, sans-serif;


$pt-font-family-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
