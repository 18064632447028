@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";


div.loginComponent {
  @include media-breakpoint-up(sm) {
    max-width: 350px;
    min-width: 300px;
  }

  .login {
    text-align: left;
  }

  .confirmationCallout {
    padding-right: 1em;
    padding-bottom: 1em;
    padding-top: 0.5em;
  }

  .oldForm {
    background: rgba(255, 255, 255, 0.65);
    margin-top: 2em;
    padding: 2em;
    border-radius: 1em;
  }
}
