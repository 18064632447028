@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";

.provisionComponent {
  width: 1000px;

  @include media-breakpoint-down(lg) {
    width: 95%;
  }

  .formTooltipHelper {
    padding: 0.5em;
    line-height: 2em;
    color: $pt-icon-color;
  }

  .unprovisioned {
    .flex-spaced.footer {
      margin: -20px;
      margin-top: 1em;
      padding: 20px calc(20px + 1em);
      box-shadow: inset 0 15px 50px -20px #ebf1f5
    }
  }

  .steps {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    align-content: space-evenly;
    align-items: center;
    width: 100%;

    .step {
      flex-grow: 1;
      display: flex;
      line-height: 1.9em;

      &.status0 {
        .number {
          color: $light-gray1;
          background-color: #fff;
        }

        .name {
          color: $light-gray1;
        }
      }

      &.status1 {
        .number {
          background-color: #fff;
          border-color: $pt-intent-primary;
          color: $pt-intent-primary;
        }

        .name {
          font-weight: bold;
        }
      }

      &.status2 {
        .number {
          background-color: #fff;
          border-color: $pt-intent-success;
          color: $pt-intent-success;
          font-size: 2em;
          width: 1.1em;
          line-height: 0.85em;
        }

        .name {
          cursor: pointer;
          color: $pt-intent-success;
        }
      }

      .number {
        display: inline-block;
        width: 2.2em;
        text-align: center;
        border: solid 2px $light-gray1;
        border-radius: 1.5em;
        line-height: 1.9em;
        font-weight: bold;
        padding: 0;
        transition: all $pt-transition-duration $pt-transition-ease;
      }

      .name {
        white-space: nowrap;
        overflow: hidden;
        max-width: calc((100vw - 200px) / 4);
        text-overflow: ellipsis;
        text-shadow: 0 -1px 1px #fff;
        border: solid 2px transparent;
        margin-left: 0.5em;
        transition: all $pt-transition-duration $pt-transition-ease;
      }
    }
  }

  .provisionParams {
    min-height: 600px;
  }

  .logo {
    width: 84px;
    min-width: 84px;

    img.unsupported {
      filter: grayscale(1);
    }
  }

  .info {
    flex-grow: 1;

    h3 {
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    p {
      margin-bottom: 0;
    }
  }

  .details {
    margin: 1em 0;

    p {
      margin: 1em 0;
    }
  }

  .providerList {
    .logo > img {
      margin: auto
    }

    .provider {
      .intro {
        overflow: hidden;
        height: 0;
        transition: height ease 100ms;
      }

      &.preview {
        .intro {
          height: auto;
        }
      }

      &:hover {
        background-color: solid 1px $pt-text-selection-color;
      }

      margin-top: 0.5em;
      cursor: pointer;
      background-color: #fff;
    }

    margin-bottom: 1em;
  }

  .selectedProvider {


    .settingsTabs {
      margin-top: 1em;

      .#{$ns}-tab-list {
        background-color: $light-gray5;
        padding: 0.25em 1em
      }
    }

    .settingsTabsTitle {
      line-height: 2em;
      font-weight: bold;
    }

    .settingsPanel {
      margin: 0 1em;
    }

    .footer {
      button + button {
        margin-left: 0.5em;
      }
    }

    .confirmationDetails {
      margin-top: 2em;

      .#{$ns}-tab-panel {
        width: 100%;

        & > div > h4 {
          margin-top: -2em;
        }
      }
    }

    .instructions, .provisionConfirmed {
      margin-top: 1em;
    }
  }


  .provisioning {


  }

  .provisionHelperCode {
    background: rgba(0, 0, 0, 0.01);
    border: solid 1px rgba(0, 0, 0, 0.02);
    display: block;
    padding: 1px 10px;
    box-shadow: 1px 3px 5px inset rgba(0, 0, 0, 0.01);
    border-radius: 2px;
  }


  .container {
    margin-top: 0.25em;
    &.indent {
      margin-left: 1em;

    }

    .label {
      font-weight: 500;
    }

    .items {
      display: flex;
      gap: 1em
    }

    &.stack {
      .items {
        flex-direction: column;
      }
    }

    &.shelf {
      .items {
        flex-direction: row;

        div {
          max-width: 50em;
        }
      }
    }
  }
}

