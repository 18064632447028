@import "~@blueprintjs/core/lib/scss/variables";

div.manifestDesignerComponent {
  width: 100%;
  padding: 0;
  height: calc(100vh - 50px);

  > div {
    height: inherit;
  }

  .#{$ns}-control-group {
    background-color: $light-gray5;
    box-shadow: 0px 1px 3px -2px #000;
  }

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin: 0.5em;
    }
  }

  .paneContainer {
    display: flex;
    height: inherit;

    .containersPane {
      background-color: $light-gray3;
      width: 20vw;
      height: inherit;
      display: flex;
      flex-direction: column;

      .chartsFilter {
        height: 30px;

        .#{$ns}-control-group {
          border-radius: 3px;
        }

        .chartsSearch {
          margin: 3px;

          input {
            height: 24px;
          }
        }
      }

      .chartDetails {
        background-color: $light-gray1;
        box-shadow: 0px 0px 5px -3px #000;
        border: solid 1px rgba($black, 0.1);
        padding: 1em;

        img {
          float: left;
          margin-right: 1em;
          margin-bottom: 0.5em;
        }

        .link {
          font-size: 0.8em;
          padding-bottom: 0.5em;
        }

        .name {
          margin-bottom: 0.25em;

        }

        .description {
          color: $dark-gray1
        }

        .version {
          font-size: 0.8em;
          color: $dark-gray2;
          margin-bottom: 0.35em;
        }
      }

      .charts {
        overflow-y: scroll;
        border-right: solid 1px rgba($black, 0.1);
        box-shadow: 0px 0px 3px 0px inset rgba(0, 0, 0, 0.5);
        flex-grow: 1;

        .chart {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          cursor: grab;
          padding: 2px 2px 2px 12px;

          .name {
            align-self: stretch;
            padding-left: 2px;
          }

          Button {
            margin-left: auto;
            align-self: flex-end;
          }

          &:hover {
            background-color: $pt-intent-primary;

            .name {
              color: white;
            }
          }

          img {
            margin: 3px;
          }

          line-height: 22px;

          &.selected {
            background-color: $pt-text-selection-color;
          }
        }


        .addCustomChart {
          padding: 1em;
          margin: 1em;
          margin-top: 5em;
          font-size: 0.9em;
          position: relative;
          bottom: 0;
        }
      }
    }

    .designerPane {
      background-color: $light-gray4;
      flex-grow: 1;
      height: inherit;

      .toolbar {
        .#{$ns}-control-group {
          border-radius: 3px;
        }

        .zoom {
          select {
            width: 6em;
          }
        }
      }

      .srd-diagram {
        margin-top: 1px;
        height: calc(100vh - 81px);
        overflow: scroll;
        box-shadow: 0px 0px 3px -2px inset rgba(0, 0, 0, 0.5);
        transition: all $pt-transition-duration $pt-transition-ease-bounce;

        @keyframes dash {
          from {
            stroke-dashoffset: 24
          }
          to {
            stroke-dashoffset: 0
          }
        }

        .srd-default-link {
          stroke-dasharray: 10, 2;
          animation: dash 1s linear infinite
        }

        .srd-default-port {
          .name {
            padding: 0 5px;
          }
        }

        .srd-default-node {
          border-color: rgba(#000, 0.2);
          box-shadow: 0 0 1px rgba(#000, 0.5);
          border-width: 1px;
        }

        .srd-default-port--out {
          margin-left: 5px;
        }

        .srd-node--selected {
          outline: #00c0ff 1px dotted;
          outline-offset: 5px;
          border-radius: 3px;

          .srd-default-node {
            border-color: rgba($pt-text-selection-color, 1) !important;
            box-shadow: 0 0 5px rgba(#000, 0.5), 0 5px 20px inset rgba(#fff, 0.5);
          }
        }
      }

      .srd-node--selected > * {
        box-shadow: 0 0 5px rgba(#00f, 0.5);
      }
    }

    .inspectorPane {
      background-color: $light-gray3;
      width: 30vw;

      .componentSelector {

        .activeComponent {
          border: none;
          width: 100%;
        }

        select {
          background: transparent;
        }
      }

      .header {
        display: flex;
        background-color: $light-gray2;

        div {
          padding: 5px;
          overflow: hidden;
          font-weight: bold;
          border-bottom: solid 1px $light-gray1;
          box-shadow: 1px 2px 0 rgba(255, 255, 255, 0.25) inset, -1px -1px 0px -2px rgba($light-gray1, 0.15) inset;

          &.p {
            width: 30%;
          }

          &.v {
            width: 70%;
          }
        }


      }

      .values {
        overflow-y: scroll;
        height: calc(100vh - 110px);

        .group.openMenu {
          background-color: $light-gray1;
        }

        .group {
          display: flex;
          border-bottom: solid 1px $light-gray1;

          &.isSecure {
            color: green;
          }

          &.isRequired .p {
            font-weight: 600;
          }


          .p {
            width: 30%;
            padding: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 0.9em;

            .#{$ns}-icon {
              vertical-align: baseline;
            }

            & > span {
              width: 100%;

              div {
                text-overflow: ellipsis;
                overflow: hidden;
                width: inherit;
                cursor: pointer;
              }
            }

            &.g {
              color: $dark-gray5
            }
          }

          .v {
            width: 70%;
            padding: 1px;
            font-size: 12px;

            .vst {
              padding: 3px;
              line-height: 24px;
              color: $dark-gray5;
            }

            .#{$ns}-popover-wrapper, .#{$ns}-popover-target, .#{$ns}-input-group {
              width: 100%;
            }

            .#{$ns}-input {
              background-color: transparent;
              padding: 0 3px;
              border: solid 1px transparent;
              box-shadow: none;
            }

            .#{$ns}-html-select {
              select {
                width: 100%;
                height: 25px;
                box-shadow: none;
                background: transparent;
              }

              .#{$ns}-icon-double-caret-vertical {
                top: 6px
              }
            }

            .#{$ns}-input:focus {
              background-color: white
            }
          }
        }

        .info {
          padding: 1em;
          font-size: 0.8em;
          color: $pt-text-color-muted;
          font-weight: 200;
          line-height: 2em;
        }
      }
    }
  }

}

.chartLoaderOverlay {
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

.chartsSettingsPopover {
  padding: 1em;
  min-width: 500px;
  min-height: 150px;

  h3 {
    margin-top: 0;
  }


  .addCustomHelmRepo {
    margin-top: 1em;

    .addCustomHelmRepoSetup {
      font-size: 0.9em;
      padding: 1em;
      background-color: $light-gray5;

      td {
        width: 50%;
      }

      .#{$ns}-form-group {
        margin: 0;
      }

    }
  }
}


.solution-navigator-menu {
  left: calc(25%);
  width: 50%;

}
