@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";

.clustersListComponent {
  max-width: 1000px;

  .supermuted {
    opacity: 0.5;
    display: none;
  }

  .lead {
    font-size: 1.5em;
    line-height: 1.2em;
    padding-left: 1.5em;
    font-weight: 200;
    letter-spacing: 0.02em;
  }

  p, .description {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .clustersBox {
    @media (min-width: 700px) {
      display: grid;
      grid: auto-flow / 1fr 1fr;
    }
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;

    .card {
      margin: calc(1vw + 5px);
      align-self: flex-start;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .clustersHeading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    padding-right: calc(1vw + 5px);

    h1 {
      margin-left: calc(1vw + 5px);
    }
  }
}

kbd {
  background: rgba(0, 0, 0, 0.25);
  letter-spacing: -1px;
  padding: 0 5px;
  border-radius: 2px;
  font-family: $pt-font-family-monospace;
}
