@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";

.consoleExampleComponent {
  width: 100%;
  position: relative;
  margin: 1em 0;
  padding: 1px;
  background-color: $dark-gray3;
  border-radius: 3px;

  .console {
    background-color: transparent;
    color: $light-gray5;
    width: 100%;
    padding: 13px 15px 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-width: 0;
    line-height: 1.4;
    font-family: $pt-font-family-monospace;
    font-size: 13px;
    border-radius: 3px;
    height: auto;
  }

  .consoleToolbar {
    position: absolute;
    top: 0.5em;
    min-height: 2em;
    width: 8em;
    right: 0.5em;
    text-align: right;

    .#{$ns}-button {
      font-size: 0.8em;
      line-height: 0.5em;
      padding: 0.0em 0.5em;
      transition: all $pt-transition-ease $pt-transition-duration;
      margin-left: 0.1em;
    }
  }

  .fullscreenButton {
    background: rgba(250, 250, 250, 0.25);
  }

  .copyButton {
    background: rgba(250, 250, 250, 0.25);
  }

  .console.hasScroll + .consoleToolbar {
    right: 2em;
  }

  .console.hasFullScreen {
    max-height: 100%;
    font-size: 14px;
  }

  textarea {
    resize: none;
  }

  .tips {
    background-color: floralwhite;
  }
}
