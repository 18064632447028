@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";
@import "~xterm/css/xterm.css";

.reactTerminalComponent {
  position: relative;
  background: black;
  margin-bottom: 1em;
  min-height: 200px;
  height: inherit;
  padding: 0;
  border-radius: 3px;
  border: solid 1px $dark-gray1;

  & > div {
    height: inherit
  }

  div.xterm {
    height: inherit;
    margin: 0;
    padding: 1em 2em 1em 1em;
  }


  .consoleToolbar {
    z-index: 5;
    position: absolute;
    top: 0.5em;
    min-height: 2em;
    width: 8em;
    right: 1.5em;
    text-align: right;

    .#{$ns}-button {
      font-size: 0.8em;
      line-height: 0.5em;
      padding: 0.0em 0.5em;
      transition: all $pt-transition-ease $pt-transition-duration;
      margin-left: 0.1em;
    }
  }

  .fullscreenButton {
    background: transparent;
  }

  .copyButton {
    background: transparent;
    color: $pt-icon-color;
  }

  .console.hasScroll + .consoleToolbar {
    right: 2em;
  }

  .console.hasFullScreen {
    max-height: 100%;
    font-size: 14px;
  }
}
