@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";


.secureStorageComponent {
  width: 1000px;

  @include media-breakpoint-down(lg) {
    width: 95%;
  }


  .row {
    padding: 1px;
    box-shadow: $pt-input-box-shadow;
    background: #fdfdfd;

    .#{$ns}-navbar {
      padding-left: 0;
      box-shadow: none;
    }
  }

  .row + .row {
    padding-top: 0;
  }

  pre {
    white-space: pre-wrap;
    margin-right: 0.5em;
    font-size: 0.8em;
  }

  .#{$ns}-editable-text-content {
  }

  .keyArea {
    white-space: pre-wrap;
    margin: 1em;


    box-shadow: $pt-input-box-shadow;

    .#{$ns}-editable-text-content, .#{$ns}-editable-text, .#{$ns}-editable-text-input {
      min-width: 900px;
      max-width: 900px;
      padding: 1em;
      margin: 1em;
      @include media-breakpoint-down(lg) {
        min-width: 80vw;
        padding: 0.1em;
      }
      font-family: $pt-font-family-monospace;
      word-wrap: anywhere;
    }
  }

  .certificateDetails {
    p, pre {
      margin-left: 1em;
      margin-top: 1em;
    }
  }
}
