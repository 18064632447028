div.destroyClusterComponent {
  max-width: 1000px;

  .flex-spaced.footer {
    margin: -20px;
    margin-top: 1em;
    padding: 20px calc(20px + 1em);
    box-shadow: inset 0 15px 50px -20px #ebf1f5
  }

  form .footer {
    button + button {
      margin-left: 0.5em;
    }
  }
}
