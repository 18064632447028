div.manifestEditComponent {
  width: 100%;
  padding: 0;
  height: calc(100vh - 50px);

  > div {
    height: inherit;
  }

  .clusterView {
    position: relative;

    .header {
      position: absolute;
      right: 1em;
      z-index: 2;

      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin: 0.5em;
      }
    }
  }
}


