@import "../../app/media";

@keyframes playButtonColor {
  from {
    color: rgba(255, 255, 255, 0);
  }
  to {
    color: rgba(255, 255, 255, 0.5);
  }
}

@keyframes playOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcomeComponent {
  background-size: cover;
  width: 100%;
  height: inherit;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(158, 229, 255, 0.75), rgba(0, 78, 156, 0.83)), url(./image0.jpeg);
  transition: all 300ms ease;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  .mainBlock {

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    padding: 10px;
    transition: all 300ms ease;
    z-index: 1;
    align-self: center;

    .textBlock {
      padding-bottom: 10em;
      transition: all 300ms ease;
      animation-duration: 1s;
      animation-name: playOpacity;

      .meet {
        transition: all 300ms ease;
        margin-top: 0;
        font-weight: 100;
        font-size: calc(2em);
        margin-bottom: 0;
      }

      @media (max-width: 600px) {
        margin-left: 250px;
        padding-bottom: 1em;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: calc(7em);
        color: #0067B9;
        text-shadow: 0px 5px 200px #fff, 10px 15px 100px #fff;
      }

      p {
        // margin-top: -1em;
        font-weight: bold;
        font-size: 1.21em;
      }

      .getStarted {
        margin-top: 0em;
        text-align: right;

        a.getStartedLink {
          margin-top: 3em;
          outline: none;
          color: #111;
          border: solid 1px #111;
          text-decoration: none;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 0.1em;
          font-size: 2em;
          padding: 0.2em 1em;
        }

        a.getStartedLink:hover {
          background: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  @media (min-height: 500px) {
    height: calc(100vh - 50px);
    .main {
      .left:after {
        // top: -850px;
      }

      .left {
        .textBlock {
          .meet {
            margin-top: calc(50vh - 350px);
          }
        }
      }
    }
  }
}
