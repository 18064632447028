@import "~@blueprintjs/core/lib/scss/variables";

.certificateAuthoritiesComponent {
  a:hover {
    text-decoration: none;
  }

  a:hover div.#{$ns}-card p {
    text-decoration: none;
  }
}
