@import "~@blueprintjs/core/lib/scss/variables";

.chartMuseumComponent {
  max-width: 1000px;

  .#{$ns}-tab-list {
    overflow-x: auto;
  }

  .chartTitle {
    margin: -20px;
    padding: 20px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    .chartDescription {
      margin-left: 0.5em;
    }
  }

  .chartCard {
    max-width: 700px;

    h3 {
      margin-top: 0;
      padding-top: 0;
    }

    .chartVersionPanel {
      ul {
        margin-top: 0;
      }

      .versionInfo {
        margin: 10px 0;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 10px;
        font-size: 0.9em;
        border-radius: 3px;
      }

      img {
        float: right;
        max-width: 110px;
        max-height: 200px;
        border: solid 1px rgba(0, 0, 0, 0.05);
        padding: 5px;
        margin-left: 10px;
      }
    }
  }
}
