$svg-icon-map: (
        '16px/small-minus.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='%23fff'/",
        '16px/small-tick.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='%23fff'/",
        '16px/chevron-right.svg': "path fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 0 0-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 0 0 1.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235C7080'/",
        '16px/more.svg': "g fill='%235C7080'%3E%3Ccircle cx='2' cy='8.03' r='2'/%3E%3Ccircle cx='14' cy='8.03' r='2'/%3E%3Ccircle cx='8' cy='8.03' r='2'/%3E%3C/g",
);

@function svg-icon($inline-svg, $fill-color) {
  @return url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3C" + map-get($svg-icon-map, $inline-svg) + "%3E%3C/svg%3E")
}


@import "~@blueprintjs/core/lib/scss/variables";
@import "palette";
@import "media";
@import "~@blueprintjs/core/src/blueprint";
@import "~@blueprintjs/select/src/components/index";
// @import "~@blueprintjs/icons/src/generated/_icon-variables";
// @import "~@blueprintjs/icons/src/generated/_icon-map";
// @import "~@blueprintjs/icons/src/icons";

@import "~@blueprintjs/icons";

body {
  background-color: $pt-app-background-color;
}

.main {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;


  > div {
    min-height: 300px;
    @media (min-width: 1000px) {
      min-width: 700px;
    }
    padding-top: 2em;
    padding-bottom: 1em;
  }
}

button {
  margin: 0;
}

.logo {
  a {
    outline: none
  }

  img {
    max-height: 32px;
    margin: -10px 0px -13px 0px;
    border: 0;
  }
}


input[type="text"]:invalid,
input[type="email"]:invalid,
input[type="password"]:invalid {

}

.#{$ns}-input {
  &:not(:placeholder-shown):invalid {
    @include pt-input-intent($pt-intent-danger);
  }

  &:required:valid:focus {
    @include pt-input-intent($pt-intent-success);
  }
}

.#{$ns}-navbar-heading .#{$ns}-breadcrumb a {
  color: $light-gray1;
}

h1 {
  @include media-breakpoint-down(sm) {
    word-wrap: break-word;
    word-break: break-word;
  }
}


.heading {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;


}

.flex {
  display: flex;
}

.flex-spaced {
  display: flex;
  justify-content: space-between;
}

.flex-centered {
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}


.hidden-xs {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.advancedRadioGroup {
  .#{$ns}-radio {
    background-color: rgba($pt-intent-success, 0.0);
    padding-left: 3em;
    border: solid 1px white;
    border-radius: $pt-border-radius;
    transition: all $pt-transition-duration $pt-transition-ease;
    padding-right: 1em;
    padding-bottom: 1em;

    &.selected {
      background-color: rgba($pt-intent-success, 0.01);
      border-color: $pt-intent-success;
    }

    .radio {
      display: inline-grid;
    }
  }
}

.invisibleConsole {
  opacity: 0.01;
  height: 1px;
  width: 1px;
  position: absolute;
  right: 1px;
  top: 1px;
}
