@import "~@blueprintjs/core/lib/scss/variables";
@import "../../app/palette";
@import "../../app/media";

div.clusterLogsComponent {
  max-width: 1000px;
  width: 1000px;

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-down(lg) {
    width: 95%;
  }

  .flex-spaced.footer {
    margin: -20px;
    margin-top: 1em;
    padding: 20px calc(20px + 1em);
    box-shadow: inset 0 15px 50px -20px #ebf1f5
  }

  form .footer {
    button + button {
      margin-left: 0.5em;
    }
  }

  .logList {
    width: 75%;
    flex-grow: 1;
    height: calc(100vh - 200px);
    margin-bottom: 1em;
  }

  .tips {
    margin-left: 1em;
    width: 25%;
    background-color: floralwhite;
    position: relative;
    margin-top: 1em;
    max-height: 50vh;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    .tip {
      margin: 1em;
      max-height: calc(50vh - 4em);

      img {
        max-width: 100%;
        height: auto;
      }
    }

    h5 {
      margin-top: 0;
    }

    .footerButtons {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    iframe {
      height: auto;
    }
  }
}
